import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link, Prompt, useHistory } from 'react-router-dom';
import _ from 'lodash';
import DefaultLayout from "layouts/DefaultLayout";
import FieldComponent from 'shared/components/FieldComponent';
import SidebarComponent from 'shared/components/SidebarComponent';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import PreviewFieldComponent from "shared/components/PreviewFieldComponent";
import PageLoadingComponent from 'shared/components/PageLoadingComponent';
import HtmlComponent from "shared/components/HtmlComponent";
import LoadButton from 'shared/components/LoadButton';
import StatusComponent from 'shared/components/StatusComponent';
import CommentsComponent from 'components/CommentsComponent';
import SectionTree from 'views/requirementaffected/List/SectionTree';
import { useForm } from 'shared/hooks/Form';
import { useToggler } from "shared/hooks/Toggler";
import { useComments } from 'hooks/Comments';
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import AccessApi from 'shared/api/Access';
import RequirementAffectedApi from "api/RequirementAffected";
import { getApplicabilities } from 'data/ApplicabilityState';
import { getConformities } from 'data/SheetAffected';
import { getPriorities } from 'data/Priority';
import { padNumber, formatDate, compileDataToSelectOptions, compileErrorsFromResponse } from "shared/services/Utils";
import { canPerform, denyIfCantPerform } from "shared/services/Can";
import { prefixLinkTo } from 'shared/services';

export default function RequirementAffectedForm(props) {

    const [, toggle,,,,, getToggler] = useToggler({
        'general': true,
        'headquarter-comment': true,
        'comments': true,
        'conformity': true,
    });
    const [loading, setLoading] = useState(true);
    const [requirementAffected, setRequirementAffected] = useState(null);
    const history = useHistory();
    const [account] = useAccount();
    const [canWrite] = useAccess();
    const [saving, setSaving] = useState(false);
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const [commentActions, commentErrors, onCommentErrors, resetCommentErrors, doCommentActions, setCommentActions] = useComments(RequirementAffectedApi);
    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({});
    const [pilots, setPilots] = useState([]);

    denyIfCantPerform(props, "sheetaffected:read", {account: account});

    const sidebarLinks = [
        {anchor: "#general", label: "Niveau général", active: true},
        {anchor: "#comments", label: "Commentaire client", active: true},
        {anchor: "#conformity", label: "Conformité", active: true},
    ];

    useEffect(() => {
        RequirementAffectedApi
            .get(props.match.params.id)
            .then(newData => {
                setData(formatData(newData));
                setRequirementAffected(newData);
                setHasWriteAccess(canWrite(newData.sheet_affected.watchsite.id));

                AccessApi
                    .getUsersWithWriteAccess(newData.sheet_affected.watchsite.id)
                    .then(users => {
                        setPilots(
                            compileDataToSelectOptions(users, 'id',
                                [
                                    'firstname',
                                    'lastname',
                                    {
                                        field:'email',
                                        render:(v) => '(' + v + ')'
                                    }
                                ]
                            )
                        );
                    });

                setLoading(false);
            })
            .catch(() => {
                toast.error("Une erreur est survenue lors de la récupération de la fiche");
                history.goBack();
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id]);

    function formatData(formData) {
        return {
            'id': formData.id,
            'priority': formData.priority,
            'conformity': formData.conformity,
            'evaluation_date': formData.evaluation_date ? new Date(formData.evaluation_date) : null,
            'review_date': formData.review_date ? new Date(formData.review_date) : null,
            'comments': formData.comments || [],
            'hidden': formData.hidden ? 1 : 0,
            'pilot': formData.pilot ? formData.pilot.id : null,
        };
    }

    function submit(event) {
        event.preventDefault();
        setSaving(true);
        resetCommentErrors();

        doCommentActions(requirementAffected.id, data.comments).then(() => {
            let json = {...data};
            json.hidden = data.hidden === 1 ? true : false;
            json.comments = [];

            const submitButton = event.nativeEvent.submitter.name;

            RequirementAffectedApi
                .save(json)
                .then((newData) => {
                    setData(formatData(newData));
                    setRequirementAffected(newData);

                    setErrors({});
                    setSaving(false);
                    toast.success("Enregistrement effectué.");

                    if (newData.indicators.next && submitButton === "save-and-next-requirement-affected") {
                        reloadOrClose(event, props, "requirements-affected", newData.indicators.next);
                    } else {
                        reloadOrClose(event, props, "requirements-affected", newData.id);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.data.message) {
                            toast.error(error.response.data.message);                    
                        } else {
                            setErrors(compileErrorsFromResponse(error.response));
                            toast.error("Des erreurs sont survenues");
                        }
                    }
                });
        }).catch((error) => {
            setSaving(false);
            onCommentErrors(error);
        });
    }

    if (loading === true) {
        return <PageLoadingComponent label="Conformité d'une exigence" />
    }

    const requirement = requirementAffected.requirement;
    const sheetAffected = requirementAffected.sheet_affected;
    const sheet = sheetAffected.sheet;

    return (
        <DefaultLayout screen="E35" title="Conformité d'une exigence">
            <Prompt
                when={formHasModifications()}
                message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
            />
            <h1>Conformité de l'exigence <strong>{requirement.id}</strong> pour le point de veille <strong>{sheetAffected.watchsite.label}</strong></h1>
            <section className="sidebar">
                <SidebarComponent
                    label="Informations publiques"
                    links={sidebarLinks}
                />

                <section>
                    <ToggableBlocComponent label="Niveau général" id="general" toggled={getToggler("general")} toggle={toggle}>
                        <div className="bg-blanc border-b border-gris-40">
                            <div className="row">
                                <div className="col-1">{sheet.major_text && <span><i className="icon-autres-texte-majeur color-vert-apave" aria-hidden="true"></i></span>} {padNumber(sheet.id)}</div>
                                <div className="col-11"><Link to={prefixLinkTo() + `/sheets-affected/${sheetAffected.id}`} target="_blank" title={`Voir la fiche ${sheet.title}`}>
                                    <strong className="color-vert-apave">
                                        <HtmlComponent>{sheet.title}</HtmlComponent>
                                    </strong>
                                </Link></div>                                
                            </div>
                        </div>
                        <div className="bg-blanc border-b border-gris-40">
                            <PreviewFieldComponent label="Exigence">
                                <strong>{padNumber(requirement.id)}</strong> : <HtmlComponent>{requirement.description.split('\n').join("<br>")}</HtmlComponent>                                
                            </PreviewFieldComponent>
                            <PreviewFieldComponent label="Article">
                                {requirement.article}
                            </PreviewFieldComponent>
                        </div>
                        <div className="bg-gris-10 border-b border-gris-40">
                            <PreviewFieldComponent label="Emplacement dans l'arborescence">
                                {requirement.section && <SectionTree sections={requirement.section} />}
                            </PreviewFieldComponent>
                        </div>
                        <div className="bg-blanc border-b border-gris-40">
                            <PreviewFieldComponent label="Etat d'applicabilité">
                                <StatusComponent 
                                    border
                                    value={requirement.applicability_state}
                                    options={getApplicabilities()}
                                />
                            </PreviewFieldComponent>
                        </div>
                        <div className="bg-gris-10 border-b border-gris-40">
                            <PreviewFieldComponent label="Date de début d'applicabilité">
                                {formatDate(requirement.applicability_start_at)}
                            </PreviewFieldComponent>
                            <PreviewFieldComponent label="Date de fin d'applicabilité">
                                {formatDate(requirement.applicability_end_at)}
                            </PreviewFieldComponent>
                            {console.log(requirement)}
                            <PreviewFieldComponent label="Date de création">
                                {formatDate(requirementAffected.created_at)}
                            </PreviewFieldComponent>
                        </div>
                    </ToggableBlocComponent>

                    <ToggableBlocComponent
                        label={"Commentaire(s) " + _.truncate(account.name, {length: 150})}
                        id="comments"
                        toggled={getToggler("comments")}
                        toggle={toggle}
                    >
                        <CommentsComponent
                            value={data.comments}
                            onChange={values => setValue("comments", values)}
                            actions={commentActions}
                            onAction={values => setCommentActions(values)}
                            errors={commentErrors}
                            watchsite={sheetAffected.watchsite}
                        />
                    </ToggableBlocComponent>                    
                    {canPerform("account:compliance_management", {account: account}) &&
                        <form onSubmit={submit} id="form-sheet-affected" className="form">
                            <ToggableBlocComponent
                                label={"Conformité pour " + sheetAffected.watchsite.label}
                                id="conformity"
                                toggled={getToggler("conformity")}
                                toggle={toggle}
                                className="toggle-group border border-gris-40"
                                margin={20}
                            >
                                <div className="bg-blanc border-b border-gris-40">
                                    <div className="row">                        
                                        <div className="col-md-6">
                                            <FieldComponent 
                                                wrapButtonsClassName="statuts conformity"
                                                type="buttons"
                                                name="conformity" 
                                                label="Statut de conformité" 
                                                value={data.conformity}
                                                error={errors.conformity}
                                                onChange={value => setValue("conformity", parseInt(value))} 
                                                options={getConformities()}
                                                preview={!hasWriteAccess}
                                                disabled={account.is_start === true}
                                            />
                                        </div>             
                                        <div className="col-md-6">
                                            <FieldComponent 
                                                type="buttons"
                                                name="priority" 
                                                label="Priorité" 
                                                value={data.priority} 
                                                error={errors.priority}
                                                onChange={value => setValue("priority", parseInt(value))} 
                                                options={getPriorities()}
                                                preview={!hasWriteAccess}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={"border-b border-gris-40 bg-blanc"}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <FieldComponent 
                                                type="date"
                                                name="evaluation_date" 
                                                label="Date d'évaluation" 
                                                value={data.evaluation_date}
                                                error={errors.evaluation_date}
                                                onChange={value => setValue("evaluation_date", value)} 
                                                preview={!hasWriteAccess}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <FieldComponent 
                                                type="date"
                                                name="review_date" 
                                                label="Date de revue" 
                                                value={data.review_date}
                                                error={errors.review_date}
                                                onChange={value => setValue("review_date", value)} 
                                                preview={!hasWriteAccess}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={"border-b border-gris-40 bg-gris-10"}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <FieldComponent 
                                                type="radio"
                                                name="hidden" 
                                                label="Exigence masquée" 
                                                value={data.hidden}
                                                error={errors.hidden}
                                                onChange={value => setValue("hidden", parseInt(value))} 
                                                options={[
                                                    {value: 1, label: "Oui"},
                                                    {value: 0, label: "Non"}
                                                ]}
                                                preview={!hasWriteAccess}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="border-b border-gris-40 bg-gris-10">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <FieldComponent 
                                                type="select"
                                                name="pilot" 
                                                label="Pilote" 
                                                value={data.pilot}
                                                error={errors.pilot}
                                                onChange={value => setValue("pilot", value)} 
                                                clearable
                                                options={pilots}
                                                preview={!hasWriteAccess}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="flex-label">
                                                <label>Date de désignation :</label>
                                                <span id="pilot_updated_at">{formatDate(requirementAffected.pilot_updated_at, true) || "Inconnue"}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ToggableBlocComponent>
                            <section className="row">
                                <div className="col text-right">
                                    {hasWriteAccess && <>
                                        <LoadButton 
                                            loading={saving} 
                                            label="Enregistrer" 
                                            name="save" 
                                            id="save-requirement-affected"
                                        />
                                        {requirementAffected.indicators.next && 
                                            <LoadButton 
                                                loading={saving} 
                                                label="Enregistrer et passer à la conformité suivante"
                                                name="save-and-next-requirement-affected"
                                                id="save-and-next-requirement-affected"
                                            />
                                        }
                                    </>}
                                </div>
                            </section>
                            <hr />
                            <section className="row">
                                <div className="col text-left">
                                    {requirementAffected.indicators.previous && 
                                        <Link id="link-to-next-requirement-affected" className="btn btn-primary" to={prefixLinkTo() + `/requirements-affected/${requirementAffected.indicators.previous}`}>
                                            <i className="icon-left-open" aria-hidden="true"></i>&nbsp;Conformité précédente
                                        </Link>
                                    }
                                </div>
                                <div className="col text-right">
                                    <Link id="link-to-history" className="btn btn-primary" to={prefixLinkTo() + `/history/requirements_affected/${requirementAffected.id}`} target="_blank">
                                        <i className="icon-boutons-voir-historique" aria-hidden="true"></i>&nbsp;Voir l'historique
                                    </Link>
                                    {canPerform("account:actionplan", {account: account}) && <>
                                        {hasWriteAccess && <Link id="requirement-affected-new-action" className="btn btn-primary" to={prefixLinkTo() + `/actions/new?watchsites=${sheetAffected.watchsite.id}&requirements=${requirementAffected.requirement.id}`}>
                                            <i className="icon-wrench" aria-hidden="true"></i>&nbsp;Créer une action
                                        </Link>}
                                        <Link id="requirement-affected-actions" className="btn btn-primary" to={prefixLinkTo() + `/actions?watchsite=${sheetAffected.watchsite.id}&requirement=${requirementAffected.requirement.id}`}>
                                            <i className="icon-nav-mon-plan-actions" aria-hidden="true"></i>&nbsp;Plan d'actions
                                        </Link>
                                    </>}
                                    {requirementAffected.indicators.next && 
                                        <Link id="link-to-next-requirement-affected" className="btn btn-primary" to={prefixLinkTo() + `/requirements-affected/${requirementAffected.indicators.next}`}>
                                            Conformité suivante&nbsp;<i className="icon-right-open" aria-hidden="true"></i>
                                        </Link>
                                    }
                                </div>
                            </section>
                        </form>
                    }
                </section>
            </section>
        </DefaultLayout>
    );
}