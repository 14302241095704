import { React, Modal, _, DefaultLayout, useEffect, useState, useContext } from 'commons';
import { NavLink } from 'react-router-dom';
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import SheetAffectedListItem from 'views/sheetaffected/list/ListItem';
import SheetAffectedListBatch from 'views/sheetaffected/list/ListBatch';
import SheetAffectedListExportForm from 'views/sheetaffected/list/ListExportForm';
import SheetAffectedLoadSearch from 'views/sheetaffected/list/LoadSearch';
import SheetAffectedSaveSearch from 'views/sheetaffected/list/SaveSearch';
import AutoaffectationForm from 'partials/AutoaffectationForm';
import { getConformities, getConformityModes } from 'data/SheetAffected';
import { getPriorities } from 'data/Priority';
import { useList, usePosition, useQuery } from 'shared/hooks';
import { ReferentialContext } from 'shared/stores';
import { SheetAffectedApi, AccountApi, ExternalUserApi } from 'shared/api';
import { ListComponent, FilterComponent, LoadButton } from 'shared/components';
import { AccountUtils, FileUtils, TreeUtils, smallOverflowVisibleModalCustomStyles, smallModalCustomStyles, preventDefault, canPerform, denyIfCantPerform, convertCriteriesToSearchQuery, compileDataToSelectOptions, toggleArrayValue, prefixLinkTo } from 'shared/services';
import { REFERENTIALS, getDegree } from 'shared/data';
import { toast } from 'react-toastify';
import FieldComponent from '../../shared/components/FieldComponent';

export default function SheetAffectedList(props) {

    const [initializePosition, setPosition] = usePosition("sheetaffected-list");
    const [autoaffecting, setAutoaffecting] = useState(false);
    const [loadingsearch, setLoadingsearch] = useState(false);
    const [savingsearch, setSavingsearch] = useState(false);
    const [pilots, setPilots] = useState([]);
    const [availableResponsible, setAvailableResponsible] = useState([]);
    const [sheet_for_autoaffectation, setSheetForAutoaffectation] = useState(null);
    const [basicSearch, setBasicSearch] = useState(true);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [tree, setTree] = useState([]);
    const [indicator, setIndicator] = useState({});
    const [accountTree, setAccountTree] = useState({'nodes': [], 'watchsites': []});
    const [selection, setSelection] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [exportOpening, setExportOpening] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [account] = useAccount();
    const [,, writableWatchsites] = useAccess();
    const [referentialContext] = useContext(ReferentialContext);

    let exportOptions = [];
    if (account.is_start === false) {
        exportOptions = [
            {value: "simple", label: "Simple"},
            {value: "complete", label: "Avec exigences"}
        ];
    } else {
        exportOptions = [{value: "simple", label: "Simple"}];
    }
    
    // referential
    const text_types = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_TYPE], 'id', 'value');
    const text_impacts = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_IMPACT], 'id', 'value');
    const sources = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_SOURCE], 'id', 'value');
    const transmitters = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TRANSMITTER], 'id', 'value');
    const involved_actors = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR], 'id', 'value');
    const involved_functions = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION], 'id', 'value');
    const descriptors = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_DESCRIPTORS], 'id', 'value');
    const domainTree = referentialContext["restrictedTree"];
    const categoryTree = referentialContext[REFERENTIALS.REFERENTIAL_CATEGORY];

    // account access
    const hasTechnicalLevelAccess = canPerform("account:technical_level", {account: account});
    const hasComplianceManagementAccess = canPerform("account:compliance_management", {account: account});
    const hasPrivateSheetWriteAccess = canPerform("sheet:private:write", {writableWatchsites: writableWatchsites});
    const hasCustomerCommentAccess = canPerform("account:customer_comment", {account: account});
    const hasConsultantCommentAccess = canPerform("account:consultant_level", {account: account});
    const hasHeadquarterCommentAccess = canPerform("account:headquarter_comment", {account: account});
    const hasActionAccess = canPerform("account:actionplan", {account: account});
    const hasCategoriesAccess = canPerform("account:categories", {account: account});

    denyIfCantPerform(props, "sheetaffected:list", {account: account});

    const query = useQuery();
    const initialCriterias = {};
    initialCriterias.hidden = "0";
    initialCriterias.excludes = [];
    initialCriterias.keyword_fields = ["sheet.reglementary_text"];
    let reinitCriterias = false;
    if (query.has("conformity")) {
        initialCriterias.conformity = query.get("conformity").split(",").map(c => parseInt(c));
        reinitCriterias = true;
    }
    if (query.has("review_date")) {
        initialCriterias.review_date = {end: new Date(query.get("review_date"))};
        reinitCriterias = true;
    }
    if (query.has("watchsite")) {
        initialCriterias.watchsites = ["watchsite:" + query.get("watchsite")];
        reinitCriterias = true;
    }
    if (query.has("sheet")) {
        initialCriterias.sheet_id = query.get("sheet");
        reinitCriterias = true;
    }
    if (hasComplianceManagementAccess) {
        if (query.has("pilot")) {
            initialCriterias.pilot = parseInt(query.get("pilot"));
            reinitCriterias = true;
        }
    }

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh,,
        loadCriterias
    ] = useList("sheet-affected-list", SheetAffectedApi, preFetch, "sheet.id", initialCriterias, "DESC");

    let filterType = {
        keyword: {type: "match", fields: criterias.keyword_fields},
        sheet_id: {type: "match", fields: ["sheet.id"]},
        watchsites: {type: "terms", fields: ["tree"]},
        sheet_text_type: {type: "terms", fields: ["sheet.text_types"]},
        sheet_text_date: {type: "range", fields: ["sheet.text_date"]},
        sheet_publication_date: {type: "range", fields: ["sheet.publication_date"]},
        sheet_major_text: {type: "match", fields: ["sheet.major_text"], cast: "int"},
        hidden: {type: "match", fields: ["hidden"], cast: "int"},
        private: {type: "match", fields: ["sheet.is_private"], cast: "int"},
        favorite: {type: "match", fields: ["favorite"], cast: "int"},
        sheet_categories: {type: "terms", fields: ["sheet.categories"]},
        excludes: {type: "match", fields: ["excludes"]}
    };
    if (hasComplianceManagementAccess) {
        filterType.conformity_mode = {type: "match", fields: ["conformity_mode"]};
        filterType.conformity = {type: "terms", fields: ["conformity"]};
        filterType.review_date = {type: "range", fields: ["review_date"]};
        filterType.evaluation_date = {type: "range", fields: ["evaluation_date"]};
        filterType.priority = {type: "match", fields: ["priority"]};
        filterType.pilot = {type: "terms", fields: ["pilot"]};
    }
    if (hasActionAccess) {
        filterType.has_action = {type: "gte", fields: ["nb_actions"], forcevalue: 1};
    }
    if (hasCategoriesAccess) {
        filterType.categories = {type: "terms", fields: ["categories"]};
    }

    let filterTypeAdvanced = {...filterType, ...{
        text_number: {type: "match", fields: ["sheet.text_number"]},
        nor: {type: "match", fields: ["sheet.nor"]},
        repealed_text: {type: "match", fields: ["sheet.repealed_text"], cast: "int"},
        cut_into_requirements: {type: "match", fields: ["sheet.cut_into_requirements"], cast: "int"},
        source: {type: "terms", fields: ["sheet.source"]},
        transmitter: {type: "terms", fields: ["sheet.transmitter"]},
        descriptors: {type: "terms", fields: ["sheet.descriptors"]},
        responsable_evaluation: {type: "match", fields: ["responsable_evaluation"]},
        affectation_date: {type: "range", fields: ["affectation_date"]},
        hidden_date: {type: "range", fields: ["hidden_date"]},
        deadlines: {type: "range", fields: ["sheet.deadlines.date"]},
    }};
    if (hasTechnicalLevelAccess) {
        filterTypeAdvanced.corporal_impact_degree = {type: "terms", fields: ["sheet.corporal_impact_degree"]};
        filterTypeAdvanced.legal_impact_degree = {type: "terms", fields: ["sheet.legal_impact_degree"]};
        filterTypeAdvanced.media_impact_degree = {type: "terms", fields: ["sheet.media_impact_degree"]};
        filterTypeAdvanced.financial_impact_degree = {type: "terms", fields: ["sheet.financial_impact_degree"]};
        filterTypeAdvanced.text_impacts = {type: "terms", fields: ["sheet.text_impacts"]};
        filterTypeAdvanced.involved_actors = {type: "terms", fields: ["sheet.involved_actors"]};
        filterTypeAdvanced.involved_functions = {type: "terms", fields: ["sheet.involved_functions"]};        
    }

    let keywordFields = [
        {value: 'sheet.title', label: 'Titre'},
        {value: 'sheet.reglementary_text', label: 'Texte'},
        {value: 'sheet.attachments', label: 'Fichiers APAVE'},
        {value: 'attachments', label: 'Fichier client'},
    ];
    if (hasCustomerCommentAccess) {
        keywordFields.push({value: 'comments', label: 'Commentaire client'});
    }
    if (hasConsultantCommentAccess) {
        keywordFields.push({value: 'comment_consultant', label: 'Commentaire consultant'});
    }
    if (hasHeadquarterCommentAccess) {
        keywordFields.push({value: 'comment_headquarters', label: 'Commentaire siège'});
    } 

    useEffect(() => {
        if (reinitCriterias) {
            loadCriterias(initialCriterias, advancedSearch ? filterTypeAdvanced : filterType);
            props.history.push("/sheets-affected");
        } else {
            submitSearch(advancedSearch ? filterTypeAdvanced : filterType, () => initializePosition(), false);
        }

        AccountApi.getUserTree(true).then(t => setAccountTree(t));
        ExternalUserApi
            .list({active: 1, valid: 1, sort: "lastname", limit: "1000"})
            .then(([r]) => {
                setPilots(r.map(u => { return { value: u.user_id, label: u.firstname + " " + u.lastname }}));

                account.pilot_consultants.forEach(c => r.push({user_id: c.id, firstname: c.firstname, lastname: c.lastname}));
                account.associate_consultants.forEach(c => r.push({user_id: c.id, firstname: c.firstname, lastname: c.lastname}));

                setAvailableResponsible(
                    r
                        .sort((a, b) => a.lastname.localeCompare(b.lastname))
                        .map(u => { 
                            return { value: u.user_id, label: u.firstname + " " + u.lastname }
                        })
                );
            });
        
        // eslint-disable-next-line
    } , []);

    // eslint-disable-next-line
    useEffect(() => setTree(TreeUtils.accountTreeFilterMapper(accountTree, true, criterias.watchsites)), [accountTree]);
    
    useEffect(() => {
        if (_.isArray(criterias.excludes)) {
            const excludePilot = criterias.excludes.includes(1);
            const excludeCategory = criterias.excludes.includes(2);
            const excludeSubCategory = criterias.excludes.includes(3);
            if (excludePilot && criterias.pilot) {
                addCriteria('pilot', null);
            }
            if ((excludeCategory || excludeSubCategory) && _.isArray(criterias.categories) && criterias.categories.length > 0) {
                if (excludeCategory && excludeSubCategory) {
                    addCriteria('categories', []);
                } else {
                    const filtered = criterias.categories.filter((category) => {
                        for (let c = 0; c < categoryTree.length; c++) {
                            if (excludeCategory) {
                                if (categoryTree[c].value === category) {
                                    return false;
                                }
                            } else if (excludeSubCategory) {
                                const subCategoryTree = categoryTree[c].children;
                                for (let sc = 0; sc < subCategoryTree.length; sc++) {
                                    if (subCategoryTree[sc].value === category) {
                                        return false;
                                    }
                                }
                            }
                        }
                        return true;
                    });
                    if (!_.isEqual(criterias.categories, filtered)) {
                        addCriteria('categories', filtered);
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [criterias]);

    function updateSelection(sheet, data, checked) {
        let newSelection = selection.slice(0);
        if (checked) {
            data.forEach(i => {
                newSelection.push({
                    sheet: sheet,
                    sheetaffected: i.sheetaffected,
                    watchsite: i.watchsite
                });
            });
            
            let numberOfRows = 0;
            rows.forEach(row => {
                numberOfRows = numberOfRows + row.data.length;
            });
            setAllSelected(numberOfRows === newSelection.length);

        } else {
            setAllSelected(false);
            data.forEach(i => {
                _.remove(newSelection, (o) => o.sheetaffected === i.sheetaffected);
            });
        }
        setSelection(newSelection);
    }

    function updateAllSelection(checked) {
        setAllSelected(checked);
        let newSelection = [];
        if (checked) {
            rows.forEach(row => {
                row.data.forEach(item => {
                    newSelection.push({
                        sheet: row.sheet.id,
                        sheetaffected: item.id,
                        watchsite: item.watchsite_id
                    });
                });
            });
        }
        setSelection(newSelection);
    }

    function preFetch(newSorting, newDirection, newCriterias, newLimit, newPage, newFilters) {
        if (newCriterias.has_action === "0") {
            newFilters.has_action = {type: "match", fields: ["nb_actions"], cast: "int"};
        }

        if (hasComplianceManagementAccess) {
            SheetAffectedApi
                .searchIndicators(
                    convertCriteriesToSearchQuery(newCriterias, newFilters)
                )
                .then(data => setIndicator(data.conformity));
        }

        if (newSorting === "conformity") {
            newDirection = [0,3,4,5,1,2];
        }

        return [newSorting, newDirection, newCriterias, newLimit, newPage, newFilters];
    }

    function clearfilter() {
        props.history.push(prefixLinkTo() + "/sheets-affected");
        refresh(true, true, {hidden: "0", keyword_fields: ["sheet.reglementary_text"]});
    }

    function openAutoaffectation(event, sheet) {
        event.preventDefault();
        if (account.is_start === false) {
            setSheetForAutoaffectation(sheet);
            setAutoaffecting(true);
        }
    }

    function runExport(mode) {
        setExporting(true);

        let filter = (advancedSearch ? filterTypeAdvanced : filterType);
        let newCriterias = {...criterias};
        if (newCriterias.has_action === "0") {
            filter.has_action = {type: "match", fields: ["nb_actions"], cast: "int"};
        }

        const exportQuery = convertCriteriesToSearchQuery(criterias, filter);
        const fileName = FileUtils.getFilePrefix() + "-MaVeille-" + mode + ".xlsx";
        return SheetAffectedApi
            .export(exportQuery, mode, fileName)
            .then(data => {
                toast.success("L'export a été initié, vous serez notifié lorsqu'il sera disponible.");
            })
            .catch((error) => {
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Périmètre de recherche trop important, veuillez le réduire.");
                }                
            })
            .finally(() => setExporting(false));
    }

    function toggleExclusion(name, state) {
        let value = 1;
        if (name === 'cat') {
            value = 2;
        } else if (name === 'scat') {
            value = 3;
        }

        let values = _.isArray(criterias.excludes) ? [...criterias.excludes] : [];
        if (values.includes(value)) {
            values = values.filter(item => item !== value);
        } else {
            values.push(value);
        }

        addCriteria('excludes', values);
    }

    return (     
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E31" title="Ma veille">
            <h1>Ma veille</h1>
            {hasComplianceManagementAccess && <>
                <div className="progress">
                    {indicator.new > 0 && <>
                        {indicator.new > 2 && <div className="progress-bar bg-conformity-new" role="progressbar" title={`Nouveau : ${indicator.new}%`} style={{width: `${indicator.new}%`}} displayed-value={`${indicator.new} %`} aria-valuenow={indicator.new} aria-valuemin="0" aria-valuemax="100"></div>}
                        {indicator.new <= 2 && <div className="progress-bar bg-conformity-new" role="progressbar" title={`Nouveau : ${indicator.new}%`} style={{width: `${indicator.new}%`}} aria-valuenow={indicator.new} aria-valuemin="0" aria-valuemax="100"></div>}
                    </>}
                    {indicator.tospecified > 0 && <>
                        {indicator.tospecified > 2 && <div className="progress-bar bg-conformity-tospecified" role="progressbar" title={`A préciser : ${indicator.tospecified}%`} style={{width: `${indicator.tospecified}%`}} displayed-value={`${indicator.tospecified} %`} aria-valuenow={indicator.tospecified} aria-valuemin="0" aria-valuemax="100"></div>}
                        {indicator.tospecified <= 2 &&<div className="progress-bar bg-conformity-tospecified" role="progressbar" title={`A préciser : ${indicator.tospecified}%`} style={{width: `${indicator.tospecified}%`}} aria-valuenow={indicator.tospecified} aria-valuemin="0" aria-valuemax="100"></div>}
                    </>}
                    {indicator.nok > 0 && <>
                        {indicator.nok > 2 && <div className="progress-bar bg-conformity-nok" role="progressbar" title={`En écart : ${indicator.nok}%`} style={{width: `${indicator.nok}%`}} displayed-value={`${indicator.nok} %`} aria-valuenow={indicator.nok} aria-valuemin="0" aria-valuemax="100"></div>}
                        {indicator.nok <= 2 &&<div className="progress-bar bg-conformity-nok" role="progressbar" title={`En écart : ${indicator.nok}%`} style={{width: `${indicator.nok}%`}} aria-valuenow={indicator.nok} aria-valuemin="0" aria-valuemax="100"></div>}
                    </>}
                    {indicator.ok > 0 && <>
                        {indicator.ok > 2 && <div className="progress-bar bg-conformity-ok" role="progressbar" title={`Conforme : ${indicator.ok}%`} style={{width: `${indicator.ok}%`}} displayed-value={`${indicator.ok} %`} aria-valuenow={indicator.ok} aria-valuemin="0" aria-valuemax="100"></div>}
                        {indicator.ok <= 2 &&<div className="progress-bar bg-conformity-ok" role="progressbar" title={`Conforme : ${indicator.ok}%`} style={{width: `${indicator.ok}%`}} aria-valuenow={indicator.ok} aria-valuemin="0" aria-valuemax="100"></div>}
                    </>}
                </div>
                <ul className="progress-legend">
                    <li><span className="bg-conformity-new"></span>Nouveau{indicator.new > 0 && <> ({indicator.new} %)</>}</li>
                    <li><span className="bg-conformity-tospecified"></span>A préciser{indicator.tospecified > 0 && <> ({indicator.tospecified} %)</>}</li>
                    <li><span className="bg-conformity-nok"></span>En écart{indicator.nok > 0 && <> ({indicator.nok} %)</>}</li>
                    <li><span className="bg-conformity-ok"></span>Conforme{indicator.ok > 0 && <> ({indicator.ok} %)</>}</li>
                </ul>
            </>}
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(advancedSearch ? filterTypeAdvanced : filterType))} >
                    <section className="filters">
                        <header>
                            <div className="row">
                                <div className="col-md-2"><h2><button className={basicSearch ? "toggler" : "toggler actif"} type="button" onClick={() => setBasicSearch(!basicSearch)}>Recherche</button></h2></div>
                                <div className="col-md-2"><h2><button className={advancedSearch ? "toggler" : "toggler actif"} type="button" onClick={() => setAdvancedSearch(!advancedSearch)}>Recherche avancée</button></h2></div>
                            </div>
                        </header>
                        <div className={basicSearch ? "bg-gris-25" : "d-none"}>
                            <div className="row">
                                <FilterComponent 
                                    type="keyword"
                                    name="keyword"
                                    label="Recherche libre" 
                                    fields={keywordFields}
                                    selectedFields={criterias.keyword_fields}
                                    onChange={value => addCriteria("keyword", value)}
                                    onFieldChange={value => addCriteria("keyword_fields", toggleArrayValue(criterias.keyword_fields, value))}
                                    value={criterias.keyword || ""} 
                                />
                                <FilterComponent type="text" name="sheet_id" onChange={value => addCriteria("sheet_id", value)} value={criterias.sheet_id} label="Numéro de fiche" />
                                {hasComplianceManagementAccess && <>
                                    <FilterComponent type="select" name="conformity_mode" onChange={value => addCriteria("conformity_mode", value)} value={criterias.conformity_mode} label="Mode gestion" options={getConformityModes()} clearable />
                                    <FilterComponent type="buttons" name="conformity" onChange={value => addCriteria("conformity", value)} value={criterias.conformity} label="Conformité" clearable options={getConformities()} multiple />
                                </>}
                                <FilterComponent type="dropdown-tree-select" name="sheet_categories" onChange={value => addCriteria("sheet_categories", value)} value={criterias.sheet_categories}  label="Domaine / Sous domaine / Thème" data={domainTree} mode="multiSelect" />
                                <FilterComponent type="select" name="sheet_text_type" onChange={value => addCriteria("sheet_text_type", value)} value={criterias.sheet_text_type} label="Type de texte" clearable options={text_types} multiple />                                
                                {hasCategoriesAccess && 
                                    <FilterComponent
                                        type="dropdown-tree-select"
                                        name="categories"
                                        onChange={value => addCriteria("categories", value)}
                                        value={criterias.categories}
                                        label={AccountUtils.getCategoriesLabel(account)}
                                        data={categoryTree}
                                        mode="multiSelect"
                                        disabled={_.isArray(criterias.excludes) && criterias.excludes.includes(2) && criterias.excludes.includes(3)}
                                    >
                                        <fieldset className="mb-0">
                                            <div>
                                                <FieldComponent
                                                    type="checksingle"
                                                    checksingleLabel={"Sans " + AccountUtils.getCategoryLabel(account)}
                                                    onChange={(value) => toggleExclusion('cat', value)}
                                                    checked={_.isArray(criterias.excludes) && criterias.excludes.includes(2)}
                                                    name="exclude_categories"
                                                    wrapper={([,input,,,inputLabel]) => <>{input}{inputLabel}</>}
                                                />
                                                <FieldComponent
                                                    type="checksingle"
                                                    checksingleLabel={"Sans " + AccountUtils.getSubCategoryLabel(account)}
                                                    onChange={(value) => toggleExclusion('scat', value)}
                                                    checked={_.isArray(criterias.excludes) && criterias.excludes.includes(3)}
                                                    name="exclude_subcategories"
                                                    wrapper={([,input,,,inputLabel]) => <>{input}{inputLabel}</>}
                                                />
                                            </div>
                                        </fieldset>
                                    </FilterComponent>
                                }
                                <FilterComponent type="dropdown-tree-select" name="watchsites" onChange={value => addCriteria("watchsites", value)} value={criterias.watchsites} data={tree} mode="multiSelect" label="Point de veille ou noeud" />
                                <FilterComponent type="daterange" name="sheet_text_date" onChange={value => addCriteria("sheet_text_date", value)} value={criterias.sheet_text_date} label="Date du texte" />
                                <FilterComponent type="daterange" name="sheet_publication_date" onChange={value => addCriteria("sheet_publication_date", value)} value={criterias.sheet_publication_date} label="Date de publication" />
                                {hasComplianceManagementAccess && <>
                                    <FilterComponent type="daterange" name="evaluation_date" onChange={value => addCriteria("evaluation_date", value)} value={criterias.evaluation_date} label="Date d'évaluation" />
                                    <FilterComponent type="daterange" name="review_date" onChange={value => addCriteria("review_date", value)} value={criterias.review_date} label="Date de revue" />
                                </>}
                                <FilterComponent type="radio" name="sheet_major_text" onChange={value => addCriteria("sheet_major_text", value)} value={criterias.sheet_major_text} label="Texte majeur" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                {hasActionAccess && <>
                                    <FilterComponent type="radio" name="has_action" onChange={value => addCriteria("has_action", value)} value={criterias.has_action} label="Fiche avec action" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                </>}
                                <FilterComponent type="radio" name="hidden" onChange={value => addCriteria("hidden", value)} value={criterias.hidden} label="Fiche masquée" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                <FilterComponent type="radio" name="private" onChange={value => addCriteria("private", value)} value={criterias.private} label="Fiche privée" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                {hasComplianceManagementAccess && <>
                                    <FilterComponent type="select" name="priority" onChange={value => addCriteria("priority", value)} value={criterias.priority} label="Priorité" clearable options={getPriorities()} />
                                    <FilterComponent
                                        type="select"
                                        name="pilot"
                                        onChange={value => addCriteria("pilot", value)}
                                        value={criterias.pilot} label="Pilote"
                                        clearable
                                        options={pilots}
                                        disabled={_.isArray(criterias.excludes) && criterias.excludes.includes(1)}
                                    >
                                        <fieldset className="mb-0">
                                            <div>
                                                <FieldComponent
                                                    type="checksingle"
                                                    checksingleLabel="Sans pilote"
                                                    onChange={(value) => toggleExclusion('pilot', value)}
                                                    checked={_.isArray(criterias.excludes) && criterias.excludes.includes(1)}
                                                    name="exclude_pilot"
                                                    wrapper={([,input,,,inputLabel]) => <>{input}{inputLabel}</>}
                                                />
                                            </div>
                                        </fieldset>
                                    </FilterComponent>
                                </>}
                                <FilterComponent type="radio" name="favorite" label="Favoris" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} value={criterias.favorite} onChange={value => addCriteria("favorite", value)} />
                            </div>
                        </div>

                        <section className={basicSearch && advancedSearch ? "" : "d-none"}>
                            <header className="bg-gris-40">
                                <h3><button className="toggler actif" type="button" onClick={() => setAdvancedSearch(!advancedSearch)}>Recherche avancée</button></h3>
                            </header>
                            <div className="bg-gris-25">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="alert alert-warning" role="alert">
                                            <p>L'utilisation des critères de recherche avancée peut augmenter de manière conséquente les temps de recherche</p>
                                        </div>
                                    </div>
                                    <FilterComponent type="text" name="text_number" onChange={value => addCriteria("text_number", value)} value={criterias.text_number} label="Numéro de texte" />
                                    <FilterComponent type="text" name="nor" onChange={value => addCriteria("nor", value)} value={criterias.nor} label="Numéro NOR" />
                                    <FilterComponent type="radio" name="repealed_text" onChange={value => addCriteria("repealed_text", value)} value={criterias.repealed_text} label="Texte abrogé" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                    <FilterComponent type="radio" name="cut_into_requirements" onChange={value => addCriteria("cut_into_requirements", value)} value={criterias.cut_into_requirements} label="Découpé en exigence" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                    <FilterComponent type="select" name="source" onChange={value => addCriteria("source", value)} value={criterias.source} label="Source" multiple={true} options={sources} />
                                    <FilterComponent type="select" name="transmitter" onChange={value => addCriteria("transmitter", value)} value={criterias.transmitter} label="Emetteur" multiple={true} options={transmitters} />
                                    <FilterComponent type="select" name="descriptors" onChange={value => addCriteria("descriptors", value)} value={criterias.descriptors} label="Descripteurs" multiple={true} options={descriptors} />
                                    {hasTechnicalLevelAccess && <>
                                        <FilterComponent type="select" name="text_impacts" onChange={value => addCriteria("text_impacts", value)} value={criterias.text_impacts} label="Impact du texte" multiple={true} options={text_impacts} />
                                        <FilterComponent type="select" name="involved_actors" onChange={value => addCriteria("involved_actors", value)} value={criterias.involved_actors} label="Acteurs concernés" multiple={true} options={involved_actors} />
                                        <FilterComponent type="select" name="involved_functions" onChange={value => addCriteria("involved_functions", value)} value={criterias.involved_functions} label="Fonctions concernées" multiple={true} options={involved_functions} />
                                    </>}
                                    <FilterComponent type="select" name="responsable_evaluation" onChange={value => addCriteria("responsable_evaluation", value)} value={criterias.responsable_evaluation} label="Responsable de la dernière évaluation" clearable options={availableResponsible} />
                                    <div className="col-md-3"></div>
                                    {hasTechnicalLevelAccess && <>
                                        <FilterComponent type="degree-checkbox" name="corporal_impact_degree" onChange={value => addCriteria("corporal_impact_degree", value)} value={criterias.corporal_impact_degree} label="Degré d'impact corporel" options={getDegree()} />
                                        <FilterComponent type="degree-checkbox" name="legal_impact_degree" onChange={value => addCriteria("legal_impact_degree", value)} value={criterias.legal_impact_degree} label="Degré d'impact juridique" options={getDegree()} />
                                        <FilterComponent type="degree-checkbox" name="media_impact_degree" onChange={value => addCriteria("media_impact_degree", value)} value={criterias.media_impact_degree} label="Degré d'impact médiatique" options={getDegree()} />
                                        <FilterComponent type="degree-checkbox" name="financial_impact_degree" onChange={value => addCriteria("financial_impact_degree", value)} value={criterias.financial_impact_degree} label="Degré d'impact financier" options={getDegree()} />
                                    </>}
                                    <FilterComponent type="daterange" name="publication_date" onChange={value => addCriteria("affectation_date", value)} value={criterias.affectation_date} label="Date de mise en ligne" />
                                    <FilterComponent type="daterange" name="hidden_date" onChange={value => addCriteria("hidden_date", value)} value={criterias.hidden_date} label="Date de masquage" />
                                    <FilterComponent type="daterange" name="deadlines" onChange={value => addCriteria("deadlines", value)} value={criterias.deadlines} label="Date d'échéance" />
                                </div>
                            </div>
                        </section>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-sheet-affected" onClick={clearfilter} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                    <button id="savefilter-sheet-affected" onClick={() => setSavingsearch(true)} type="button" className="btn btn-primary icon"><i className="icon-boutons-enregistrer-recherche" aria-hidden="true"></i>Enregistrer la recherche</button>
                                    <button id="loadfilter-sheet-affected" onClick={() => setLoadingsearch(true)} type="button" className="btn btn-primary icon"><i className="icon-boutons-recherches-enregistrees" aria-hidden="true"></i>Mes recherches enregistrées</button>                                    
                                </div>
                                <div className="col-md-3 text-right">
                                    <LoadButton id="search-sheet-affected" type="submit" label="Rechercher" loading={loading} />                                    
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <ListComponent 
                        id="sheet-affected"
                        className="table table-bordered"
                        displayHeader={false}
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={[]}
                        renderRow={(row, columns, index) => <SheetAffectedListItem 
                            selection={selection}
                            updateSelection={updateSelection}
                            row={row}
                            columns={columns}
                            index={index}
                            accountTree={accountTree}
                            openAutoaffectation={openAutoaffectation} 
                            hasComplianceManagementAccess={hasComplianceManagementAccess}
                            hasCustomerCommentAccess={hasCustomerCommentAccess}
                            hasActionAccess={hasActionAccess}
                            hasPrivateSheetWriteAccess={hasPrivateSheetWriteAccess}
                        />}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                        globalActions={(
                            <>
                                {hasPrivateSheetWriteAccess && <NavLink id="new-private-sheet" className="btn btn-primary h25" to="/sheets/private/new" title="Créer une fiche privée">
                                    <i className="icon-boutons-plus" aria-hidden="true"></i>&nbsp;Créer une fiche privée
                                </NavLink>}
                                {account.is_start === false && hasComplianceManagementAccess && <LoadButton
                                    loading={exportOpening}
                                    onClick={() => setExportOpening(true)}
                                    label="Exporter"
                                    id="export-sheetaffected"
                                    className="btn btn-primary h25"
                                    iconClass="icon-file-excel"
                                />}
                                {(account.is_start === true || !hasComplianceManagementAccess) && <LoadButton
                                    loading={exporting}
                                    onClick={() => runExport("simple")}
                                    label="Exporter"
                                    id="export-sheetaffected"
                                    className="btn btn-primary h25"
                                    iconClass="icon-file-excel"
                                />}
                            </>
                        )}
                        batchActions={<>
                            <input onChange={(e) => updateAllSelection(e.currentTarget.checked)} type="checkbox" name="all-items" id="all-items" checked={allSelected} />
                            <label title={`Sélectionner tous les éléments de la page courante (${page})`} htmlFor="all-items">&nbsp;</label>

                            <SheetAffectedListBatch
                                selection={selection}
                                history={props.history}
                                onSuccess={() => {
                                    updateAllSelection(false);
                                    refresh(false, false, {}, false);
                                }}
                            />
                        </>}
                    />
                </form>

                {account.is_start === false &&
                    <Modal isOpen={autoaffecting} onRequestClose={() => setAutoaffecting(false)} style={smallModalCustomStyles}>
                        <AutoaffectationForm
                            sheet={sheet_for_autoaffectation}
                            userTree={accountTree}
                            onRequestClose={() => {
                                setAutoaffecting(false)
                                refresh(false, false, {}, false);
                            }}
                        />
                    </Modal>
                }

                <Modal isOpen={exportOpening} onRequestClose={() => setExporting(false)} style={smallModalCustomStyles}>
                    <SheetAffectedListExportForm 
                        onRequestClose={() => setExportOpening(false)}
                        export={runExport}
                        exporting={exporting}
                        exportOptions={exportOptions}
                    />
                </Modal>

                <Modal isOpen={loadingsearch} onRequestClose={() => setLoadingsearch(false)} style={smallOverflowVisibleModalCustomStyles}>
                    <SheetAffectedLoadSearch onSearchLoad={loadCriterias} onClose={() => setLoadingsearch(false)} />
                </Modal>
                
                <Modal isOpen={savingsearch} onRequestClose={() => setSavingsearch(false)} style={smallModalCustomStyles}>
                    <SheetAffectedSaveSearch filters={criterias} onClose={() => setSavingsearch(false)} />
                </Modal>                
            </div>
        </DefaultLayout>
    );
}